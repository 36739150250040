<template>
    <div>
        <div class="page-title">报名会员列表</div>

        <div class="search-tab">
            <at-input v-model="query.keywords" class="mb-4" width="500px" placeholder="按用户id，昵称搜索">
                <button @click="getFirstList" class="btn">搜索</button>
            </at-input>
        </div>


        <a-table :columns="table.columns"
                 :rowKey="record => record.id || record.key"
                 :data-source="table.data"
                 :pagination="table.pagination"
                 @change="tableChanged"
                 :locale="{emptyText:'暂无会员'}"
                 class="members-table"
                 :loading="table.loading"
        >
            <div slot="no" slot-scope="id,item,idx">
                {{ idx + 1 }}
            </div>

            <div class="avatar" slot="avatar" slot-scope="avatar">
                <template v-if="avatar">
                    <img :src="avatar" alt="">
                </template>
                <template v-else>
                    <img src="/assets/icons/dfava.png" alt="">
                </template>
            </div>

            <div class="name" slot="name" slot-scope="name,item">
                <router-link class="member-name" :to="`/ast/members/${item.id}`">{{ name }}</router-link>
            </div>

            <div slot="level_name" slot-scope="levelName,item">
                <template v-if="item.level_id">
                    <div class="level-card">
                        Lv.{{ item.level_id }}{{ levelName }}
                    </div>
                </template>
                <template v-else>游客</template>

            </div>

            <div slot="price" slot-scope="val">
                <span v-if=" val > 0 " class="text-danger">￥{{ val }}</span>
                <span v-else> - </span>
            </div>

            <div slot="gender" slot-scope="gender">
                {{ gender === 1 ? '男' : '女' }}
            </div>
            <div slot="def" slot-scope="val">{{ val || ' - ' }}</div>

            <div slot="gj" slot-scope="val,item">
                <a-dropdown>
                    <a class="ant-dropdown-link" @click="e => e.preventDefault()">

                        {{ item.apply_status ? '已跟进' : '未跟进' }}
                        <span class="gj-icon"></span>

                    </a>
                    <a-menu slot="overlay">
                        <a-menu-item>
                            <div @click="follow(item,1)" class="gj">已跟进</div>
                        </a-menu-item>
                        <a-menu-item>
                            <div @click="follow(item,0)" class="gj">未跟进</div>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
            </div>

        </a-table>

    </div>
</template>

<script>

import api from "../../../repo/api";
import AtInput from "@/components/AtInput.vue";

export default {
    components: {AtInput},
    data() {
        return {
            detailId: 0,
            query: {
                id: 0,
                keywords: "",
            },
            course: {},
            table: {
                columns: [
                    {
                        title: 'ID',
                        dataIndex: 'member_id',
                        width: '110px',
                    },
                    {
                        title: '头像',
                        dataIndex: 'avatar',
                        scopedSlots: {customRender: 'avatar'},
                    },
                    {
                        title: '昵称',
                        dataIndex: 'name',
                        scopedSlots: {customRender: 'name'},
                    }, {
                        title: '性别',
                        dataIndex: 'gender',
                        scopedSlots: {customRender: 'gender'},
                    },
                    {
                        title: '电话',
                        dataIndex: 'phone',
                        scopedSlots: {customRender: 'def'},
                    }, {
                        title: '支付金额',
                        dataIndex: 'paid_amount',
                        scopedSlots: {customRender: 'price'},
                    },
                    {
                        title: '报名时间',
                        dataIndex: 'apply_at',
                        scopedSlots: {customRender: 'def'},
                    },
                    {
                        title: '跟进时间',
                        dataIndex: 'follow_at',
                        scopedSlots: {customRender: 'def'},
                    },
                    {
                        title: '跟进状态',
                        dataIndex: 'status',
                        scopedSlots: {customRender: 'gj'},
                    }

                ],
                loading: true,
                pagination: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 15,
                    showTotal: (total, range) => {
                        return `当前${range[0]}-${range[1]}，共 ${total} 个会员`;
                    },
                },
                data: []
            },
        }
    },
    mounted() {
        this.getFirstList()
    },
    methods: {
        getFirstList() {
            this.query.id = this.$route.params.id

            this.getList()
        },
        getList() {

            this.table.loading = true

            api.get('/course/apply-members', this.query, (data) => {

                this.table.data = data.data.data
                this.table.pagination.current = data.data.current_page
                this.table.pagination.total = data.data.total
                this.table.pagination.pageSize = data.data.per_page

                this.course = data.data.course

                this.table.loading = false

            })
        },
        follow(item, status) {

            if (item.apply_status === status) return;

            this.$loading.show()

            api.post('/course/follow', {
                id: this.query.id,
                am_id: item.id,
                status: item.apply_status ? 0 : 1
            }, (data) => {

                this.$loading.hide()

                this.$message.data(data)

                if (data.code === 0) {

                    this.getList()

                }
            })

        },
        tableChanged(pagination) {
            this.query.page = pagination.current
            this.getList()
        }
    }
}
</script>
<style lang="less" scoped>
.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;

    img {
        width: 100%;
    }
}

.level-card {
    border-radius: 8px;
    border: 1px solid #EDC483;
    color: #EDC483;
    text-align: center;
    display: inline-block;
    padding: 2px 6px;
}

.members-table /deep/ table {
    .ant-table-thead > tr > th {
        background: transparent;
        padding: 12px 12px;
    }

    .ant-table-column-title {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
    }

    .ant-table-tbody > tr > td {
        font-size: 14px;
        font-weight: 400;
        color: #606266;
        line-height: 14px;
        padding: 12px 12px;
    }
}

.ant-dropdown-link {
    color: #666666;
}

.gj {
    width: 120px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 40px;
    height: 40px;
    padding-left: 24px;
}

.gj-icon {
    display: inline-block;
    background-image: url("/assets/icons/gj-icon.png");
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
}
</style>